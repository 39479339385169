import tw, { css } from 'twin.macro'

const titleStyles = css`
  ${tw`text-title-md lg:text-title-lg font-title uppercase font-medium`};
`

const h1Styles = css`
  ${tw`text-header1-sm font-heading font-medium md:text-header1-lg`};
`

const h2Styles = css`
  ${tw`text-header2-sm font-heading font-medium md:text-header2-lg`};
`

const h3Styles = css`
  ${tw`text-header3-sm font-heading font-medium md:text-header3-lg`};
`

const h4Styles = css`
  ${tw`text-header4-sm font-heading font-normal md:text-header4-lg`};
`

const h5Styles = css`
  ${tw`text-header5-sm font-heading font-normal md:text-header5-lg`};
`

const paragraphStyles = css`
  ${tw`font-thin text-paragraph-responsive`};
`

const tagStyles = css`
  ${tw`text-tag-sm font-normal uppercase md:text-tag-lg`};
`

const btnStyles = css`
  ${tw`text-button-sm font-normal md:text-button-m`};
`

const inputTextStyles = css`
  ${tw`text-input-sm font-thin md:text-input-lg`}
`
const legalPoliciesParagraph = css`
  ${paragraphStyles}
  ${tw`my-4`}
`

export {
  titleStyles,
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  paragraphStyles,
  tagStyles,
  btnStyles,
  inputTextStyles,
  legalPoliciesParagraph
}
