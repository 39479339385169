import { breakpoints } from '../../config/design-system'

const toRem = (px, rootFontSize = 16) => `${px / rootFontSize}rem`

const mediaQuery = (styles, breakpoint = 'tablet-landscape') =>
  `@media (min-width: ${breakpoints[breakpoint]}px) {
    ${styles}
  }`

export { toRem, mediaQuery }
