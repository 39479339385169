import { styled } from 'twin.macro'
import { mediaQuery } from './helpers'

const StyledSection = styled.section`
  padding: 0 1.25rem;
  margin-bottom: 4rem;
  ${mediaQuery(`
    padding: 0 15vw;
    min-height: 100vh;
    margin-bottom: inherit;
  `)}
}
`

export { StyledSection }
