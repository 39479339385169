import React from 'react'
import PropTypes from 'prop-types'
import { baseStyles } from '../styles'
import { Global } from '@emotion/react'
import Head from './Head'

const TemplateWrapper = ({ children, title, description, withHead }) => {
  return (
    <>
      <Head title={`${title} | Dogma Creative`} description={description} />
      <Global styles={baseStyles} />
      {children}
    </>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default TemplateWrapper
