import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import appleTouchIcon from '../assets/favicon/apple-touch-icon.png'
import favicon32x32 from '../assets/favicon/favicon-32x32.png'
import favicon16x16 from '../assets/favicon/favicon-16x16.png'
import safariPinnedTab from '../assets/favicon/safari-pinned-tab.svg'

const Head = ({ children, title, description }) => {
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="apple-touch-icon" sizes="152x152" href={appleTouchIcon} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
      <link rel="mask-icon" href={safariPinnedTab} color="#df570d" />
      <meta name="msapplication-TileColor" content="#121212" />
      <meta name="theme-color" content="#121212" />
    </Helmet>
  )
}

Head.defaultProps = {
  description:
    'We bridge the gap between advertising and entertainment, working at the intersection of creativity and technology.'
}

Head.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default Head
