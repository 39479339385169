import tw, { css } from 'twin.macro'
import { colors, fontFamily, typeScale } from '../../config/design-system'
import { fontStyles } from './fonts'

const baseStyles = css`
  ${fontStyles}

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    cursor: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSI4LjUiIGZpbGw9IiNFNTU3MDgiLz48L3N2Zz4='),
      auto;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${colors['light-text-color']};
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  h1,
  h2,
  h3 {
    font-family: ${fontFamily.heading};
  }

  body {
    font-family: ${fontFamily.primary};
    font-size: ${typeScale['base-font']};
    color: ${colors['light-text-color']};
    background: ${colors['dark-bg-color']};
    -webkit-font-smoothing: antialiased;
    line-height: 1.15;
  }

  ._error-inner {
    color: red;
  }

  #nc-root {
    ${tw`font-cms`};
  }

  a {
    cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI5LjQxMTUgMTEuNDEyNEMzMy4wNTAyIDE3LjcxNDggMzAuODkwOSAyNS43NzM3IDI0LjU4ODUgMjkuNDEyNEMxOC4yODYgMzMuMDUxMSAxMC4yMjcyIDMwLjg5MTcgNi41ODg0NiAyNC41ODkzQzIuOTQ5NzUgMTguMjg2OSA1LjEwOTEyIDEwLjIyOCAxMS40MTE1IDYuNTg5MjhDMTcuNzE0IDIuOTUwNTcgMjUuNzcyOCA1LjEwOTk0IDI5LjQxMTUgMTEuNDEyNFoiIGZpbGw9IiNFNTU3MDgiLz4KPHBhdGggZD0iTTIyLjUxODIgMjEuODc1N0wyMi4xMTA0IDIxLjE2OTRDMjAuOTAzNyAxOS4wNzkyIDIxLjY2MzMgMTcuMTk5MiAyMy42MDA5IDE2LjA2MTNMMjIuOTUxOCAxNC45MzY5QzIxLjAwNTggMTYuMDYwNSAxOC45ODk1IDE1Ljc2MzkgMTcuNzkxMSAxMy42ODgyTDE3LjM4MzMgMTIuOTgxOUwxNi4zNTk5IDEzLjU3MjhMMTYuNzY3NyAxNC4yNzkxQzE3LjY5MTUgMTUuODc5MSAxOC45NTYgMTYuNTcxMyAyMC41Nzg5IDE2LjE1MzJMMjAuNjk1NCAxNi4zNTVMMTIuNDIxNCAyMS4xMzJMMTIuOTcwNyAyMi4wODMzTDIxLjI0NDcgMTcuMzA2NEwyMS4zNjEyIDE3LjUwODJDMjAuMTk2IDE4LjcxOSAyMC4xNTQ5IDIwLjE0NTggMjEuMDg3IDIxLjc2MDJMMjEuNDk0NyAyMi40NjY1TDIyLjUxODIgMjEuODc1N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo='),
      auto;
  }

  #featured-creators .swiper {
    padding: 2rem 0;
  }

  #featured-creators .swiper-wrapper {
    transition-timing-function: linear;
  }

  #featured-creators .swiper-slide {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
  }

  #featured-creators .swiper-slide:nth-of-type(odd) {
    margin-top: 2rem;
  }
`

export { baseStyles }
