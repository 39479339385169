import { css } from 'twin.macro'
import GTFlexaStandardThin from '../fonts/GT-Flexa-Standard-Thin.otf'
import GTFlexaStandardRegular from '../fonts/GT-Flexa-Standard-Regular.otf'
import GTFlexaStandardMedium from '../fonts/GT-Flexa-Standard-Medium.otf'
import GTFlexaExpandedThin from '../fonts/GT-Flexa-Expanded-Thin.otf'
import GTFlexaExpandedRegular from '../fonts/GT-Flexa-Expanded-Regular.otf'
import GTFlexaExpandedMedium from '../fonts/GT-Flexa-Expanded-Medium.otf'
import GTFlexaExpandedBlack from '../fonts/GT-Flexa-Expanded-Black.otf'
import GTFlexaExtendedThin from '../fonts/GT-Flexa-Extended-Thin.otf'
import GTFlexaExtendedRegular from '../fonts/GT-Flexa-Extended-Regular.otf'
import GTFlexaExtendedBold from '../fonts/GT-Flexa-Extended-Bold.otf'
import GTFlexaCompressedThin from '../fonts/GT-Flexa-Compressed-Thin.otf'
import GTFlexaXCompressedLight from '../fonts/GT-Flexa-X-Compressed-Light.otf'
import GTFlexaXCompressedThin from '../fonts/GT-Flexa-X-Compressed-Thin.otf'
import GTFlexaXCompressedMedium from '../fonts/GT-Flexa-X-Compressed-Medium.otf'

const fontStyles = css`
  @font-face {
    font-family: 'GT Flexa';
    src: url(${GTFlexaStandardThin}) format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa';
    src: url(${GTFlexaStandardRegular}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa';
    src: url(${GTFlexaStandardMedium}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Expanded';
    src: url(${GTFlexaExpandedThin}) format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Expanded';
    src: url(${GTFlexaExpandedRegular}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Expanded';
    src: url(${GTFlexaExpandedMedium}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Expanded';
    src: url(${GTFlexaExpandedBlack}) format('opentype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Extended';
    src: url(${GTFlexaExtendedThin}) format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Extended';
    src: url(${GTFlexaExtendedRegular}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Extended';
    src: url(${GTFlexaExtendedBold}) format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa Compressed';
    src: url(${GTFlexaCompressedThin}) format('opentype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa X Compressed';
    src: url(${GTFlexaXCompressedThin}) format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa X Compressed';
    src: url(${GTFlexaXCompressedLight}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'GT Flexa X Compressed';
    src: url(${GTFlexaXCompressedMedium}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }
`

export { fontStyles }
